import { isServer } from "@iso/lib/helpers/isServer";

const productDatas = [];
function getInitData() {
  let productQuantity = [];
  const products = {};
  const value = JSON.parse(localStorage.getItem("mallType"));
  const mallType = value != undefined ? value : "2";
  const value2 = JSON.parse(localStorage.getItem("productFrom"));
  const productFrom = value2 != undefined ? value2 : "hq";
  if (!isServer) {
    const cartProductQuantity = localStorage.getItem("cartProductQuantity");
    let cartProducts = localStorage.getItem("cartProducts");
    if (cartProducts && cartProductQuantity) {
      cartProducts = JSON.parse(cartProducts);
      JSON.parse(cartProductQuantity).forEach((product) => {
        const productID = product.productID;
        if (!isNaN(product.quantity)) {
          productQuantity.push({
            productID,
            quantity: parseInt(product.quantity, 10),
          });
          products[productID] = {
            ...cartProducts[productID],
            price: parseFloat(cartProducts[productID].memberPrice, 10),
          };
        }
      });
    } else {
      productDatas.forEach((product) => {
        productQuantity.push({
          productID: product.productID,
          quantity: 1,
        });
        products[product.productID] = product;
      });
    }
  }
  return { productQuantity, products, mallType, productFrom };
}

export default getInitData();
