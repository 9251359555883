import React, { useEffect } from "react";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import AppProvider from "./AppProvider";
import ApiCall from "./library/helpers/ApiCall";
import forceLogout from "./library/helpers/forceLogout";

window.addEventListener("click", () => {
  if (!localStorage.getItem("accessToken")) return;
  const encryptedLastActiveTime = sessionStorage.getItem("lastActiveTime");
  if (!!encryptedLastActiveTime) {
    const expiresTime = 15; //in minutes
    const lastActiveTime = JSON.parse(encryptedLastActiveTime);
    const compared = {
      stored: lastActiveTime,
      now: Date.now(),
      diff: Date.now() - lastActiveTime,
      isOver15min: Date.now() - lastActiveTime > expiresTime * 60 * 1000,
    };

    if (compared.isOver15min) {
      forceLogout(() => {
        sessionStorage.removeItem("lastActiveTime");
      });
    }
  }
  sessionStorage.setItem("lastActiveTime", JSON.stringify(Date.now()));
});

const App = () => {
  useEffect(() => {
    ApiCall.get("/basic/getSystemStatus")
      .then((res) => {
        const onlineStatus = res.data?.result?.onlineStatusCode;

        if (onlineStatus !== "1" && window.location.pathname !== "/Maintenance") {
          window.location.pathname = "/Maintenance";
        }
      })
      .catch((error) => {
        // If API call fails, redirect to maintenance page
        if (window.location.pathname !== "/Maintenance") {
          window.location.pathname = "/Maintenance";
        }
      });
  }, []);

  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <Routes />
        </>
      </AppProvider>
    </Provider>
  );
};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
