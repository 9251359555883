import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
import appActions from "@iso/redux/app/actions";
import TopbarNotification from "./TopbarNotification";
import TopbarMessage from "./TopbarMessage";
import TopbarSearch from "./TopbarSearch";
import TopbarUser from "./TopbarUser";
import TopbarAddtoCart, { TopbarAddtoCartOutside } from "./TopbarAddToCart";
import TopbarWrapper from "./Topbar.styles";
import sidebarImage from "@iso/assets/images/logo-landscape.svg";
import { Link, useParams } from "react-router-dom";
import moment from "moment";


const { Header } = Layout;
const { toggleCollapsed } = appActions;

export default function Topbar() {
  const [selectedItem, setSelectedItem] = React.useState("");
  const isLocalHost = window.location.href.includes("localhost");
  const [time, settime] = React.useState()
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.topbarTheme
  );
  const { collapsed, openDrawer } = useSelector((state) => state.App);
  const dispatch = useDispatch();
  const handleToggle = React.useCallback(
    () => dispatch(toggleCollapsed()),
    [dispatch]
  );
  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: "fixed",
    width: "100%",
    height: 70,
  };
  useEffect(()=>{
    setInterval(()=>settime(moment().utc(0).format("YYYY-MM-DD HH:mm:ss")),1000)
  },[])
  return (
    <TopbarWrapper>
      <Header
        style={styling}
        className={
          isCollapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
        }
      >
        <div className="isoLeft">
          <button
            className={
              isCollapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
            }
            style={{ color: customizedTheme.textColor }}
            onClick={handleToggle}
          />
          {/* <Link to={"/shop/" + id}>
            <img src={sidebarImage} alt="ag logo" width="70%" />
          </Link> */}
        </div>

        <ul className="isoRight">

          {/* <li className="isoSearch">
            <TopbarSearch />
          </li> */}

          {/* <li
            onClick={() => setSelectedItem('notification')}
            className={selectedItem ? 'isoNotify active' : 'isoNotify'}
          >
            <TopbarNotification />
          </li> */}

          {/* <li onClick={() => setSelectedItem('message')} className="isoMsg">
            <TopbarMessage />
          </li> */}
           <li>
            <div className="time-css">
              Today: {time}
            </div>
          </li>
          <li onClick={() => setSelectedItem("addToCart")} className="isoCart">
            <TopbarAddtoCart />
          </li>

          <li onClick={() => setSelectedItem("user")} className="isoUser">
            <TopbarUser />
          </li>
        </ul>
      </Header>
    </TopbarWrapper>
  );
}

export function OutsideTopbar() {
  const [selectedItem, setSelectedItem] = React.useState("");
  const [time, settime] = React.useState()
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.topbarTheme
  );
  const { collapsed, openDrawer } = useSelector((state) => state.App);
  const dispatch = useDispatch();
  const handleToggle = React.useCallback(
    () => dispatch(toggleCollapsed()),
    [dispatch]
  );
  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: "fixed",
    width: "100%",
    height: 70,
    padding: "0px 50px",
  };
  const { id } = useParams();
  useEffect(()=>{
    setInterval(()=>settime(moment().utc(0).format("YYYY-MM-DD HH:mm:ss")),1000)
  },[])
  return (
    <TopbarWrapper>
      <Header
        style={styling}
        className={
          isCollapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
        }
      >
        <div className="isoLeft">
          {/* <button
            className={
              isCollapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
            }
            style={{ color: customizedTheme.textColor }}
            onClick={handleToggle}
          /> */}
          <Link to={"/shop/" + id}>
            <img src={sidebarImage} alt="ag logo" width="70%" />
          </Link>
        </div>

        <ul className="isoRight">
          {/* <li className="isoSearch">
            <TopbarSearch />
          </li> */}

          {/* <li
            onClick={() => setSelectedItem('notification')}
            className={selectedItem ? 'isoNotify active' : 'isoNotify'}
          >
            <TopbarNotification />
          </li> */}

          {/* <li onClick={() => setSelectedItem('message')} className="isoMsg">
            <TopbarMessage />
          </li> */}
            <li>
            <div className="time-css">
              Today: {time}
            </div>
          </li>
          <li onClick={() => setSelectedItem("addToCart")} className="isoCart">
            <TopbarAddtoCartOutside />
          </li>

          {/* <li onClick={() => setSelectedItem("user")} className="isoUser">
            <TopbarUser />
          </li> */}
        </ul>
      </Header>
    </TopbarWrapper>
  );
}
