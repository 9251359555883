import { all, takeEvery, put } from "redux-saga/effects";
import actions from "./actions";
import fake from "./fake";
import fakeinitdata from "./config";

export function* changedCard() {
  yield takeEvery(actions.CHANGE_CARDS, function* () {});
}
export function* initData() {
  let fakeData = fakeinitdata;
  //alert(JSON.stringify(fakeinitdata));
  if (fakeinitdata.productQuantity.length === 0) {
    fakeData = fake;
  }

  yield put({
    type: actions.INIT_DATA,
    payload: fakeData,
  });
}
export function* updateData({ products, productQuantity }) {
  localStorage.setItem("cartProductQuantity", JSON.stringify(productQuantity));
  localStorage.setItem("cartProducts", JSON.stringify(products));
  yield put({
    type: actions.UPDATE_DATA,
    products,
    productQuantity,
  });
}

export function* clearCart() {
  localStorage.removeItem("cartProductQuantity");
  localStorage.removeItem("cartProducts");
  const products = [];
  const productQuantity = [];
  yield put({
    type: actions.CLEAR_CART,
    products,
    productQuantity,
  });
}

export function* changeMallType({ mallType }) {
  localStorage.setItem("mallType", JSON.stringify(mallType));

  yield put({
    type: actions.CHANGE_MALL_TYPE,
    mallType,
  });
}

export function* changeProductFrom({ productFrom }) {
  localStorage.setItem("productFrom", JSON.stringify(productFrom));

  yield put({
    type: actions.CHANGE_PRODUCT_FROM,
    productFrom,
  });
}

export default function* () {
  yield all([
    takeEvery(actions.INIT_DATA_SAGA, initData),
    takeEvery(actions.UPDATE_DATA_SAGA, updateData),
    takeEvery(actions.CLEAR_CART_SAGA, clearCart),
    takeEvery(actions.CHANGE_MALL_TYPE_SAGA, changeMallType),
    takeEvery(actions.CHANGE_PRODUCT_FROM_SAGA, changeProductFrom),
  ]);
}
