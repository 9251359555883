const actions = {
  INIT_DATA: "ECOMMERCE_INIT_DATA",
  INIT_DATA_SAGA: "ECOMMERCE_INIT_DATA_SAGA",
  UPDATE_DATA: "ECOMMERCE_UPDATE_DATA",
  UPDATE_DATA_SAGA: "ECOMMERCE_UPDATE_DATA_SAGA",
  CHANGE_VIEW: "ECOMMERCE_CHANGE_VIEW",
  VIEW_TOPBAR_CART: "ECOMMERCE_VIEW_TOPBAR_CART",
  CLEAR_CART: "ECOMMERCE_CLEAR_CART",
  CLEAR_CART_SAGA: "ECOMMERCE_CLEAR_CART_SAGA",
  CHANGE_PRODUCT_FROM: "ECOMMERCE_CHANGE_PRODUCT_FROM",
  CHANGE_PRODUCT_FROM_SAGA: "ECOMMERCE_CHANGE_PRODUCT_FROM_SAGA",
  CHANGE_MALL_TYPE: "ECOMMERCE_CHANGE_MALL_TYPE",
  CHANGE_MALL_TYPE_SAGA: "ECOMMERCE_CHANGE_MALL_TYPE_SAGA",
  initData: () => ({ type: actions.INIT_DATA_SAGA }),
  changeView: (view) => ({
    type: actions.CHANGE_VIEW,
    view,
  }),
  changeViewTopbarCart: (viewTopbarCart) => {
    return {
      type: actions.VIEW_TOPBAR_CART,
      viewTopbarCart,
    };
  },
  changeProductQuantity: (productQuantity) => {
    return (dispatch, getState) => {
      const { products } = getState().Ecommerce;
      dispatch({
        type: actions.UPDATE_DATA_SAGA,
        products,
        productQuantity,
      });
    };
  },
  addToCart: (product, quantity) => {
    return (dispatch, getState) => {
      const { products, productQuantity } = getState().Ecommerce;
      const productID = product.productID;

      productQuantity.push({ productID, quantity: quantity });
      products[productID] = product;
      dispatch({
        type: actions.UPDATE_DATA_SAGA,
        products,
        productQuantity,
      });
    };
  },
  clearCart: () => ({
    type: actions.CLEAR_CART_SAGA,
  }),
  changeProductFrom: (productFrom) => {
    return {
      type: actions.CHANGE_PRODUCT_FROM_SAGA,
      productFrom,
    };
  },
  changeMallType: (mallType) => {
    return {
      type: actions.CHANGE_MALL_TYPE_SAGA,
      mallType,
    };
  },
};
export default actions;
