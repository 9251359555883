import authAction from "@iso/redux/auth/actions";
import { store } from "../../redux/store";
import Swal from "sweetalert2";

const { logout } = authAction;

const forceLogout = (didClose = () => {}) => {
  Swal.fire({
    icon: "warning",
    title: "Please Login Again.",
    html: "Session expires. Please login again.",
    allowEnterKey: false,
    toast: true,
    customClass: {
      container: "force-logout-pop-up",
    },
    width: "500px",
    position: "top-end",
    timer: 3000,
    timerProgressBar: true,
    didClose: () => {
      store.dispatch(logout());
      didClose();
    },
  });
};

export default forceLogout;
