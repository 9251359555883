import React from "react";
import { useDispatch } from "react-redux";
import Popover from "@iso/components/uielements/popover";
import IntlMessages from "@iso/components/utility/intlMessages";
import authAction from "@iso/redux/auth/actions";
import TopbarDropdownWrapper from "./TopbarDropdown.styles";
import { getProfileData } from "../../library/helpers/utility";
import { useHistory } from "react-router-dom";

const { logout } = authAction;

export default function TopbarUser() {
  const history = useHistory();
  const profileData = getProfileData();
  const [visible, setVisibility] = React.useState(false);
  const dispatch = useDispatch();
  function handleVisibleChange() {
    setVisibility((visible) => !visible);
  }

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <div
        className="isoDropdownLink"
        onClick={() => {
          if (localStorage.getItem("tempData") === null) {
            dispatch(logout());
          } else {
            const accessData = localStorage.getItem("tempData");
            localStorage.removeItem("tempData");
            localStorage.setItem("profileData", accessData);
            history.push("/");
          }
        }}
      >
        <IntlMessages id="topbar.logout" />
      </div>
    </TopbarDropdownWrapper>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      arrowPointAtCenter={true}
      placement="bottomLeft"
    >
      <div>{profileData.fullname}</div>
    </Popover>
  );
}
