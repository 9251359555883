import React from "react";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "@iso/components/utility/intlMessages";
import Scrollbar from "@iso/components/utility/customScrollBar";
import Popover from "@iso/components/uielements/popover";
import SingleCart from "@iso/components/Cart/SingleCartModal";
import ecommerceAction from "@iso/redux/ecommerce/actions";
import TopbarDropdownWrapper from "./TopbarDropdown.styles";

const { initData, changeViewTopbarCart, changeProductQuantity } = ecommerceAction;
let totalPrice;
export default function TopbarAddtoCart() {
  let { url } = useRouteMatch();
  const dispatch = useDispatch();
  const customizedTheme = useSelector((state) => state.ThemeSwitcher.topbarTheme);
  const { productQuantity, products, loadingInitData, viewTopbarCart } = useSelector((state) => state.Ecommerce);

  function hide() {
    dispatch(changeViewTopbarCart(false));
  }
  function handleVisibleChange() {
    dispatch(changeViewTopbarCart(!viewTopbarCart));
  }
  React.useEffect(() => {
    if (!loadingInitData) {
      dispatch(initData());
    }
  }, [dispatch, loadingInitData]);

  function renderProducts() {
    totalPrice = 0;
    if (!productQuantity || productQuantity.length === 0) {
      return (
        <div className="isoNoItemMsg">
          <span>No item found</span>
        </div>
      );
    }
    return productQuantity.map((product) => {
      // console.log(products);
      // console.log(product);
      totalPrice += product.quantity * products[product.productID].productPricingList[0].memberPrice;
      return (
        <SingleCart
          key={product.productID}
          quantity={product.quantity}
          changeQuantity={changeQuantity}
          cancelQuantity={cancelQuantity}
          {...products[product.productID]}
        />
      );
    });
  }
  function changeQuantity(productID, quantity) {
    const newProductQuantity = [];
    productQuantity.forEach((product) => {
      if (product.productID !== productID) {
        newProductQuantity.push(product);
      } else {
        newProductQuantity.push({
          productID,
          quantity,
        });
      }
    });
    dispatch(changeProductQuantity(newProductQuantity));
  }
  function cancelQuantity(productID) {
    const newProductQuantity = [];
    productQuantity.forEach((product) => {
      if (product.productID !== productID) {
        newProductQuantity.push(product);
      }
    });
    dispatch(changeProductQuantity(newProductQuantity));
  }

  const content = (
    <TopbarDropdownWrapper className="topbarAddtoCart">
      <div className="isoDropdownHeader">
        <h3>
          <IntlMessages id="sidebar.cart" />
        </h3>
      </div>
      <div className="isoDropdownBody isoCartItemsWrapper">
        <Scrollbar style={{ height: 300 }}>{renderProducts()}</Scrollbar>
      </div>
      <div className="isoDropdownFooterLinks">
        <Link to={`${url}/cart`} onClick={hide}>
          <IntlMessages id="topbar.viewCart" />
        </Link>

        <h3>
          <IntlMessages id="topbar.totalPrice" />: <span>AGP {totalPrice.toFixed(2)}</span>
        </h3>
      </div>
    </TopbarDropdownWrapper>
  );
  return (
    <Popover
      content={content}
      trigger="click"
      visible={viewTopbarCart}
      onVisibleChange={handleVisibleChange}
      placement="bottomLeft"
    >
      <div className="isoIconWrapper">
        <i className="ion-android-cart" style={{ color: "#303336 !important" }} />
        {productQuantity.length === 0 ? "" : <span>{productQuantity.length}</span>}
      </div>
    </Popover>
  );
}

export function TopbarAddtoCartOutside() {
  let { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { id } = useParams();
  const customizedTheme = useSelector((state) => state.ThemeSwitcher.topbarTheme);
  const { productQuantity, products, loadingInitData, viewTopbarCart } = useSelector((state) => state.Ecommerce);

  function hide() {
    dispatch(changeViewTopbarCart(false));
  }
  function handleVisibleChange() {
    dispatch(changeViewTopbarCart(!viewTopbarCart));
  }
  React.useEffect(() => {
    if (!loadingInitData) {
      dispatch(initData());
    }
  }, [dispatch, loadingInitData]);

  function renderProducts() {
    totalPrice = 0;
    if (!productQuantity || productQuantity.length === 0) {
      return (
        <div className="isoNoItemMsg">
          <span>No item found</span>
        </div>
      );
    }
    return productQuantity.map((product) => {
      // console.log(products);
      // console.log(product);
      totalPrice += product.quantity * products[product.productID].productPricingList[0].memberPrice;
      return (
        <SingleCart
          key={product.productID}
          quantity={product.quantity}
          changeQuantity={changeQuantity}
          cancelQuantity={cancelQuantity}
          {...products[product.productID]}
        />
      );
    });
  }
  function changeQuantity(productID, quantity) {
    const newProductQuantity = [];
    productQuantity.forEach((product) => {
      if (product.productID !== productID) {
        newProductQuantity.push(product);
      } else {
        newProductQuantity.push({
          productID,
          quantity,
        });
      }
    });
    dispatch(changeProductQuantity(newProductQuantity));
  }
  function cancelQuantity(productID) {
    const newProductQuantity = [];
    productQuantity.forEach((product) => {
      if (product.productID !== productID) {
        newProductQuantity.push(product);
      }
    });
    dispatch(changeProductQuantity(newProductQuantity));
  }

  const content = (
    <TopbarDropdownWrapper className="topbarAddtoCart">
      <div className="isoDropdownHeader">
        <h3>
          <IntlMessages id="sidebar.cart" />
        </h3>
      </div>
      <div className="isoDropdownBody isoCartItemsWrapper">
        <Scrollbar style={{ height: 300 }}>{renderProducts()}</Scrollbar>
      </div>
      <div className="isoDropdownFooterLinks">
        <Link to={`/cart/` + id} onClick={hide}>
          <IntlMessages id="topbar.viewCart" />
        </Link>

        <h3>
          <IntlMessages id="topbar.totalPrice" />: <span>AGP {totalPrice.toFixed(2)}</span>
        </h3>
      </div>
    </TopbarDropdownWrapper>
  );
  return (
    <Popover
      content={content}
      trigger="click"
      visible={viewTopbarCart}
      onVisibleChange={handleVisibleChange}
      placement="bottomLeft"
    >
      <div className="isoIconWrapper">
        <i className="ion-android-cart" style={{ color: "#303336 !important" }} />
        {productQuantity.length === 0 ? "" : <span>{productQuantity.length}</span>}
      </div>
    </Popover>
  );
}
