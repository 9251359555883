const walletActions = {
  // SHOW_DRAWER: "SHOW_DRAWER",
  // HIDE_DRAWER: "HIDE_DRAWER",
  SELECT_WALLET: "SELECT_WALLET",

  // openDrawer: (payload) => ({
  //   type: walletActions.SHOW_DRAWER,
  //   payload,
  // }),
  // closeDrawer: () => ({
  //   type: walletActions.HIDE_DRAWER,
  // }),
  selectWallet: (wallet) => ({
    type: walletActions.SELECT_WALLET,
    wallet,
  }),
};

export default walletActions;
