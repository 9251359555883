import { message } from "antd";
import React from "react";
import TopbarCartWrapper from "./SingleCartModal.style";

export default function ({
  productPricingList,
  quantity,
  productImageList,
  productID,
  cancelQuantity,
  productName,
}) {
  const data =
    window.location.href.includes("checkoutSummary") ||
    window.location.href.includes("checkout");

  return (
    <TopbarCartWrapper className="isoCartItems">
      <div className="isoItemImage">
        <img alt="#" src={productImageList[0].url} />
      </div>
      <div className="isoCartDetails">
        <h3>
          {productName}
          {/* <a href="#!">{productName}</a> */}
        </h3>
        <p className="isoItemPriceQuantity">
          <span>AGP&nbsp;</span>
          <span>{productPricingList[0].memberPrice.toFixed(2)}</span>
          <span className="itemMultiplier">X</span>
          <span className="isoItemQuantity">{quantity}</span>
        </p>
      </div>
      {!data && (
        <a className="isoItemRemove" onClick={() => cancelQuantity(productID)}>
          <i className="ion-android-close" />
        </a>
      )}
    </TopbarCartWrapper>
  );
}
