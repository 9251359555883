import walletActions from "./actions";

const initialState = {
  // drawerVisibility: false,
  // drawerType: '',
  // drawerProps: {},
  walletID: "1",
  walletName: "Bonus Wallet",
};

export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    // case walletActions.SHOW_DRAWER:
    //   return {
    //     drawerVisibility: true,
    //     drawerType: action.payload.drawerType,
    //     drawerProps: action.payload.drawerProps,
    //   };
    // case walletActions.HIDE_DRAWER:
    //   return {
    //     ...state,
    //     drawerVisibility: false,
    //   };
    case walletActions.SELECT_WALLET:
      return {
        ...state,
        walletID: action.wallet.walletID,
        walletName: action.wallet.walletName,
      };
    default:
      return state;
  }
}
