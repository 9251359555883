import axios from "axios";
import { getAccessToken } from "./utility";
import forceLogout from "./forceLogout";

axios.interceptors.request.use(
  async function (config) {
    const accessToken = getAccessToken();
    config.baseURL = process.env.REACT_APP_API_KEY;
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      error?.response?.status === 401 &&
      !originalRequest?._retry &&
      error?.response?.data.message != "INVALID TOKEN"
    ) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      axios.defaults.headers.Authorization = "Bearer " + accessToken;
      return axios(originalRequest);
    } else if (error?.response?.data.message == "INVALID TOKEN") {
      forceLogout();
    }

    return Promise.reject(error);
  }
);

function refreshAccessToken() {
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/Token/RefreshToken",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        Token: getAccessToken(),
      }),
    })
      .then((response) => {
        resolve(response.data.result);
        localStorage.setItem("accessToken", response.data.result);
      })
      .catch((error) => {
        forceLogout();
      });
  });
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
